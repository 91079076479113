import React from 'react';
import PropTypes from 'prop-types';
// material components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import { cyan, blueGrey } from '@material-ui/core/colors';

import GoogleBadgeIcon from '../../images/svg/google-play-badge.svg';
import AppStoreBadgeIcon from '../../images/svg/app-store-badge.svg';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0',
  },
  actionContainer: {
    marginTop: theme.spacing(2),
  },
  actionBtn: {
    marginRight: theme.spacing(1),
  },
  storeLink: {
    position: 'relative',
  },
  ovpn: {
    position: 'absolute',
    left: 4,
    top: 4,
    height: 32,
    background: 'black',
  },
  downBtn: {
    color: blueGrey[50],
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 136,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const InstallBtn = withStyles(() => ({
  root: {
    color: blueGrey[50],
    backgroundColor: cyan[400],
    height: 40,
    width: 136,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: cyan[600],
    },
  },
}))(Button);

const stepLabels = (dev) => [
  appLabels[dev],
  'Install connection profile',
  'Start the connection',
];

const DownloadBtn = (dev, classes) => {
  switch (dev) {
    case 'android':
      return <img src={GoogleBadgeIcon} alt="google-play-badge" height={40} />;
    case 'ios':
      return <img src={AppStoreBadgeIcon} alt="app-store-badge" height={45} />;
    case 'macos':
    case 'windows':
      return <Button className={classes.downBtn}>Download Client</Button>;
    default:
      return null;
  }
};

const appLinks = {
  android: 'https://play.google.com/store/apps/details?id=io.acreto.client',
  ios: 'https://apps.apple.com/us/app/acreto-connect-client/id1560153071',
  macos: 'https://updates.acreto.net/stable/acreto-connect-client-macos-latest',
  windows:
    'https://updates.acreto.net/stable/acreto-connect-client-windows-latest',
};

const appLabels = {
  android: 'Install Acreto Connect Client from Google Play',
  ios: 'Install Acreto Connect Client from App Store',
  macos: 'Download Acreto Connect Client for macOS',
  windows: 'Download Acreto Connect Client for Windows',
};

const getStepContent = (dev, step, classes) => {
  const token = localStorage.getItem('token');
  switch (step) {
    case 0:
      return (
        <Link
          className={classes.storeLink}
          href={appLinks[dev]}
          target="_blank"
        >
          {DownloadBtn(dev, classes)}
        </Link>
      );
    case 1:
      return (
        <Link
          href={`${API_URL}/v2/tlsvpn/config/openvpn-udp?_token=${token}`}
          target="_blank"
          underline="none"
        >
          <InstallBtn>Install Profile</InstallBtn>
        </Link>
      );
    case 2:
      return null;
    default:
      return 'unkown step';
  }
};

const DownloadStepper = ({ dev }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Stepper orientation="vertical" connector={null}>
        {stepLabels(dev).map((label, index) => (
          <Step key={label} active>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <div className={classes.stepContent}>
                {getStepContent(dev, index, classes)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

DownloadStepper.propTypes = {
  dev: PropTypes.string.isRequired,
};

export default DownloadStepper;
