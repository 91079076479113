import { createMuiTheme } from '@material-ui/core/styles';
import _ from 'lodash';

import * as ACTION_TYPES from '../actions/theme/action-types';
import ThemeConfig from '../../config/themes';
import {
  defaultThemes,
  defaultThemeOptions,
  mustHaveThemeOptions,
  extendThemeWithMixins,
  mainThemeVariations,
} from '../../config/default';

const initialTheme = 'default';
const initialThemes = getInitialThemes();

const initialState = {
  mainTheme: initialTheme,
  themes: initialThemes,
  main: initialThemes[initialTheme],
};

const themeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.SET_THEME: {
      const themes =
        payload !== state.mainTheme
          ? { ...state.themes, ...updateMainThemeVariations(payload) }
          : state.themes;
      return {
        mainTheme: payload,
        themes,
        main: themes[payload],
      };
    }
    case ACTION_TYPES.SET_INITIAL_THEME: {
      return initialState;
    }
    case ACTION_TYPES.RESET_DEFAULT_THEME: {
      const themes = {
        ...state.themes,
        ...updateMainThemeVariations(initialTheme),
      };
      return {
        mainTheme: initialTheme,
        themes,
        main: themes[initialTheme],
      };
    }
    default: {
      return state;
    }
  }
};

export default themeReducer;

/**
 * THEMES
 */
function getInitialThemes() {
  const themesObj =
    Object.keys(ThemeConfig).length !== 0 ? ThemeConfig : defaultThemes;

  const themes = Object.assign(
    {},
    ...Object.entries(themesObj).map(([key, value]) => {
      const muiTheme = _.merge(
        {},
        defaultThemeOptions,
        value,
        mustHaveThemeOptions,
      );
      return {
        [key]: createMuiTheme(
          _.merge({}, muiTheme, { mixins: extendThemeWithMixins(muiTheme) }),
        ),
      };
    }),
  );

  return {
    ...themes,
    ...mainThemeVariations(themesObj[initialTheme]),
  };
}

function updateMainThemeVariations(mainTheme) {
  const themesObj =
    Object.keys(ThemeConfig).length !== 0 ? ThemeConfig : defaultThemes;
  return mainThemeVariations(themesObj[mainTheme]);
}
