import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
// material components
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import ConnectionNote from '../../components/ConnectionNote';
import DetectPlatform from '../../components/DetectPlatform';
import { getCustomerInfo, getEcosystemInfo } from '../../store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: {
    justifyContent: 'center',
    flex: 1,
  },
  error: {
    justifyContent: 'center',
    flex: 1,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
    margin: '24px 0',
    width: '100%',
  },
}));

const DeviceConnect = ({ match, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const customer = useSelector((state) => state.user.customer);
  const ecosystem = useSelector((state) => state.user.ecosystem);
  const error = useSelector((state) => state.error.TOKEN_VERIFICATION_FAILED);

  const {
    params: { cid, eid },
  } = match;
  const { state } = location;
  const localToken = localStorage.getItem('token');

  if (!state || !state.token) {
    if (!localToken) history.replace('/404');
  } else if (!localToken || localToken !== state.token) {
    localStorage.setItem('token', state.token);
  }
  function redirectPost(url, token) {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = url;

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'token';
    input.value = token;
    form.appendChild(input);

    form.submit();
  }

  useEffect(() => {
    if (cid && eid) {
      dispatch(getCustomerInfo(cid));
      dispatch(getEcosystemInfo(cid, eid));
    }
  }, [cid, eid, dispatch]);

  useEffect(() => {
    if (customer && customer.mfa_token) {
      redirectPost(customer.url, customer.mfa_token);
    }
  }, [customer]);

  if (error) {
    return (
      <div className={cx(classes.root, classes.error)}>
        <Alert severity="error" className={classes.alertIcon}>
          <AlertTitle>Error</AlertTitle>
          Your invitation to join this ecosystem is either invalid or expired.
          Please contact your administrator to get new one.
        </Alert>
      </div>
    );
  }

  if (!customer || !ecosystem) {
    return (
      <div className={cx(classes.root, classes.spinner)}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ConnectionNote customer={customer} ecosystem={ecosystem} />
      <Divider className={classes.divider} />
      <DetectPlatform customerUUID={cid} ecosystemUUID={eid} />{' '}
    </div>
  );
};

DeviceConnect.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default DeviceConnect;
