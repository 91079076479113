import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DeviceConnect from './pages/Connect';
import ClientDownload from './pages/Download';
import FourOFour from './pages/404';
import Home from './pages/Home';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route
      exact
      path="/customers/:cid/ecosystems/:eid/connect/:token"
      render={({ match }) => (
        <Redirect
          to={{
            pathname: `/customers/${match.params.cid}/ecosystems/${match.params.eid}/connect`,
            state: { token: match.params.token },
          }}
        />
      )}
    />
    <Route
      path="/customers/:cid/ecosystems/:eid/connect"
      component={DeviceConnect}
    />
    <Route
      exact
      path="/customers/:cid/ecosystems/:eid/download/:dev"
      component={ClientDownload}
    />
    <Route path="/404" component={FourOFour} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
