import React, { useState } from 'react';
// material components
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {
  AppBar,
  Button,
  Icon,
  Link,
  Toolbar,
  Typography,
  SvgIcon,
} from '@material-ui/core';

import SettingModal from '../SettingModal';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingLeft: 12,
    color: 'white',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    position: 'absolute',
    right: 1,
    minWidth: 32,
    width: 32,
    height: 32,
    opacity: 0.9,
    padding: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[500],
      opacity: 1,
    },
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  buttonIcon: {
    animation: '$rotating 3s linear infinite',
    fontSize: 24,
  },
}));

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link href="/" className={classes.link}>
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path
                  d="M12.4 14.7l-1.3-4.9-2.3-8.3c-.1-.3-.4-.4-.6-.3-.2 0-.3.1-.4.3L3.6 14.2c-.1.2.1.5.3.6.1 0 .3 0 .4-.1l4.5-3.1"
                  fill="none"
                  stroke="#03d8ff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                />
              </svg>
            </SvgIcon>
            <Typography variant="h6" className={classes.title}>
              Onboarding Portal
            </Typography>
          </Link>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <Icon className={classes.buttonIcon}>settings</Icon>
          </Button>
        </Toolbar>
      </AppBar>
      <SettingModal open={open} onClose={handleClose} />
    </>
  );
};

export default Header;
