import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Platform from 'platform';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Button, Link, Typography } from '@material-ui/core';

const styles = {
  connectBtn: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0',
  },
  otherLabel: {
    margin: '24px 0 12px',
  },
  otherConnects: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  otherBtn: {
    minWidth: 96,
    margin: '6px 12px',
  },
};

const Btn = withStyles({
  root: {
    textTransform: 'unset',
  },
})(Button);

const DEVICES = [
  { value: 'iOS', label: 'iOS' },
  { value: 'OS X', label: 'MacOS' },
  { value: 'Android', label: 'Android' },
  { value: 'Windows', label: 'Windows' },
];

const DetectPlatform = ({ classes, customerUUID, ecosystemUUID }) => {
  const history = useHistory();
  const device = _.find(DEVICES, { value: Platform.os.family });
  const otherPlatforms = _.filter(
    DEVICES,
    (dev) => dev.value !== Platform.os.family,
  );

  return (
    <>
      <Typography
        variant="h6"
        align="center"
        color={device ? 'primary' : 'textPrimary'}
        component="p"
      >
        {device ? (
          <>
            We have detected that you use <b>{Platform.os.family}</b>
          </>
        ) : (
          'Your system is not supported.'
        )}
      </Typography>
      <div className={classes.connectBtn}>
        {device ? (
          <Btn
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                `/customers/${customerUUID}/ecosystems/${ecosystemUUID}/download/${device.label}`,
              )
            }
          >
            Connect your {device.label}
          </Btn>
        ) : (
          <Link
            href="https://kb.acreto.net/how-to/connect/"
            target="_blank"
            underline="none"
          >
            <Btn variant="contained" color="primary">
              Try advanced configuration
            </Btn>
          </Link>
        )}
      </div>
      <Typography
        className={classes.otherLabel}
        align="center"
        color="secondary"
      >
        Other supported platforms
      </Typography>
      <div className={classes.otherConnects}>
        {otherPlatforms.map((dev) => (
          <Btn
            key={dev.value}
            className={classes.otherBtn}
            variant="outlined"
            color="secondary"
            onClick={() =>
              history.push(
                `/customers/${customerUUID}/ecosystems/${ecosystemUUID}/download/${dev.label}`,
              )
            }
          >
            {dev.label}
          </Btn>
        ))}
      </div>
    </>
  );
};

DetectPlatform.propTypes = {
  classes: PropTypes.object.isRequired,
  customerUUID: PropTypes.string.isRequired,
  ecosystemUUID: PropTypes.string.isRequired,
};

export default withStyles(styles)(DetectPlatform);
