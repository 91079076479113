const errorReducer = (state = {}, action) => {
	const { type, payload } = action
	const matches = /(.*)_(FAILED)/.exec(type)
	if (!matches) {
		return state
	}

  return {
    ...state,
    [type]: payload.message
  }
}

export default errorReducer;