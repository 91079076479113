import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const Theme = (props) => {
  const mainTheme = useSelector((state) => state.theme.main);

  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.element,
};

export default React.memo(Theme);
