import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { setTheme } from '../../store/actions/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '100%',
  },
}));

const SettingModal = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themes = useSelector((state) => state.theme.themes);
  const [currentTheme, setCurrentTheme] = useState(
    useSelector((state) => state.theme.mainTheme),
  );

  const handleChange = (event) => {
    setCurrentTheme(event.target.value);
  };

  const handleOk = (event) => {
    event.preventDefault();
    dispatch(setTheme(currentTheme));
    onClose();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Theme Setting</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel>Theme</InputLabel>
            <Select value={currentTheme} onChange={handleChange}>
              {Object.keys(themes).map((theme) => (
                <MenuItem value={theme} key={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SettingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SettingModal;
