import * as ACTION_TYPES from '../actions/auth/action-types';

const initialState = {
  token: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.SET_TOKEN:
      return { ...state, token: payload };
    default:
      return state;
  }
}
