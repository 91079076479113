import * as ACTION_TYPES from './action-types';
import * as REST from '../../../API';

export const getCustomerInfo = (cid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    dispatch({ type: ACTION_TYPES.GET_CUSTOMER_STARTED });
    const customer = await REST.getCustomerInfo(cid, token);
    dispatch({ type: ACTION_TYPES.GET_CUSTOMER_SUCCESS, payload: customer });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.GET_CUSTOMER_FAILED, payload: error });
  }
};

export const getEcosystemInfo = (cid, eid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_STARTED });
    const ecosystem = await REST.getEcosystemInfo(cid, eid, token);
    dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_SUCCESS, payload: ecosystem });
  } catch (error) {
    dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_FAILED, payload: error });
    dispatch({ type: ACTION_TYPES.TOKEN_VERIFICATION_FAILED, payload: { message:'Your invitation to join this ecosystem is either invalid or expired. Please contact your administrator to get new one.'}});
  }
};
