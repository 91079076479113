import React from 'react';
import PropTypes from 'prop-types';
// material comopnents
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '48px 0',
  },
  note: {
    paddingTop: 48,
    width: '72%',
  },
};

const Home = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h3" color="primary" align="center">
      Welcome to{' '}
      <Typography variant="h3" component="span" color="secondary">
        Acreto SASE <sup>+Plus</sup>
      </Typography>{' '}
      Onboarding Portal
    </Typography>
    <Paper elevation={0} className={classes.note}>
      <Typography variant="h4" color="primary" gutterBottom>
        This site is not meant to be used directly. Please contact your
        administrator to get your personal onboarding URL.
      </Typography>
      <Typography variant="h5" color="primary">
        If you want to give Acreto SASE +Plus a try, please go{' '}
        <Link href="https://acreto.io/" target="__blank">
          here
        </Link>
        .
      </Typography>
    </Paper>
  </div>
);

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
