import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import LogoIcon from '../../images/svg/acreto-logo.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  container: {
    textAlign: 'center',
  },
  logo: {
    height: 24,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <img className={classes.logo} src={LogoIcon} alt="logo" />
      </Container>
    </footer>
  );
};

export default Footer;
