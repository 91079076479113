import * as ACTION_TYPES from '../actions/user/action-types';

const initialState = {
  customer: null,
  ecosystem: null,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_CUSTOMER_SUCCESS:
      return { ...state, customer: payload };
    case ACTION_TYPES.GET_ECOSYSTEM_SUCCESS:
      return { ...state, ecosystem: payload };
    default:
      return state;
  }
};

export default userReducer;
