import axios from 'axios';

const rest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getCustomerInfo = (customerUUID, token) =>
  rest
    .get(`/v2/customers/${customerUUID}?_token=${token}`)
    .then((response) => response.data);

export const getEcosystemInfo = (customerUUID, ecosystemUUID, token) =>
  rest
    .get(
      `/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}?_token=${token}`,
    )
    .then((response) => response.data);
