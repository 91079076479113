import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// material comopnents
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import DownloadStepper from '../../components/DownloadStepper';

const useStyles = makeStyles(() => ({
  root: {
    padding: '48px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 24,
    height: 96,
  },
}));

const ClientDownload = ({ match }) => {
  const customer = useSelector((state) => state.user.customer);
  const classes = useStyles();
  const {
    params: { dev },
  } = match;

  const kbURL = process.env.REACT_APP_KB_URL;

  return (
    <div className={classes.root}>
      {customer && customer.logo && (
        <img
          className={classes.logo}
          src={customer.logo}
          alt="organization logo"
        />
      )}

      <Typography
        component="h2"
        variant="h3"
        align="center"
        color="primary"
        gutterBottom
      >
        Connect your {dev}
      </Typography>
      <Typography
        component="p"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        Please follow steps below to connect your device
      </Typography>
      <DownloadStepper dev={dev.toLowerCase()} />
      <Typography align="center" color="secondary" variant="h6" gutterBottom>
        Need help? See our documentation
      </Typography>
      <Link
        href={`${kbURL}/end-user/acreto-connect-client/${dev.toLowerCase()}`}
        target="_blank"
        underline="none"
      >
        <Button variant="outlined" color="secondary">
          Documentation
        </Button>
      </Link>
    </div>
  );
};

ClientDownload.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ClientDownload;
