import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 24,
    height: 96,
  },
};

const ConnectionNote = ({ classes, customer, ecosystem }) => (
  <div className={classes.root}>
    {customer.logo && (
      <img
        className={classes.logo}
        src={customer.logo}
        alt="organization logo"
      />
    )}

    <Typography
      component="h2"
      variant="h3"
      align="center"
      color="textPrimary"
      gutterBottom
    >
      {customer.name}
    </Typography>
    <Typography
      variant="h5"
      align="left"
      color="primary"
      component="p"
      gutterBottom
    >
      You are invited to connect to secure ecosystem{' '}
      <Typography component="b" variant="h5" color="secondary">
        {ecosystem.name}.
      </Typography>{' '}
    </Typography>
  </div>
);

ConnectionNote.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConnectionNote);
