import { combineReducers } from 'redux';

import authReducer from './auth';
import userReducer from './user';
import themeReducer from './theme';
import errorReducer from './error';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  theme: themeReducer,
  error: errorReducer
});
