import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

const initialState = {};

const middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    duration: true,
    diff: true,
  });
  middlewares.push(logger);
}

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares)),
);

export default store;
